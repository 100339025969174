/* Copyright 2024 City Trax Limited. All rights reserved. */

import {
    FormField,
    Select
} from '@cloudscape-design/components';

import { useCallback, useState, useEffect } from 'react';
import { get } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth'



function SelectTemplateFormField(props) {
    const [templates, setTemplates] = useState([]);
    const [isLoading, setIsLoading] = useState('loading');

    const load = useCallback(async () => {
        try {
            const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
            if (authToken) {
                const restOperation = get({
                    apiName: 'transcribe',
                    path: '/templates',
                    options: {
                        headers: {
                            Authorization: authToken
                        }
                    },
                });
                const { body } = await restOperation.response;
                const json = await body.json();
                const templates_array = (json)
                const result_array = []
                if (props.newTranscription) {
                    result_array.push({ label: "None", value: "0" })
                }
                result_array.push(...templates_array.map((item, index) => ({
                    label: item.template_name,
                    tags: [item.description],
                    value: (index + 1).toString()
                })))
                props.setTemplate(result_array[0])

                setTemplates(result_array)
            }
            setIsLoading('finished')

        } catch (error) {
            console.error(error)
        }

    }, []);

    useEffect(() => {
        load();
    }, [load]);



    return (

        < FormField
            label="Select a Template"
            description="Select a template file to automatically populate"
        >
            <Select
                selectedOption={props.template}
                onChange={({ detail }) => {
                    console.log(detail.selectedOption)
                    props.setTemplate(detail.selectedOption)
                }
                }
                options={templates}
                loadingText="Loading templates"
                statusType={isLoading}
                triggerVariant="option"

            />

        </FormField >
    )
}

export default SelectTemplateFormField;

