/* Copyright 2024 City Trax Limited. All rights reserved. */

import {
    Container,
    Header,
    Form,
    SpaceBetween,
    Button,
    Spinner
} from '@cloudscape-design/components';
import SummaryTypeFormField from './SummaryTypeFormField';
import { useNavigate, useLocation } from "react-router-dom";
import { useCallback, useState } from 'react';
import { post, ApiError } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';




function GenerateSummaryForm() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { file_name, file_original } = state;
    console.log(state)
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [summaryType, setSummaryType] = useState({ label: "Standard", value: "3" });

    const handleSubmit = useCallback(async () => {
        if (isSubmitting) return;

        setIsSubmitting(true);

        try {
            const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
            if (authToken) {
                const restOperation = post({
                    apiName: 'transcribe',
                    path: '/summary',
                    options: {
                        queryParams: {
                            file: file_name
                        },
                        headers: {
                            Authorization: authToken
                        },
                        body: {
                            summaryType: summaryType,
                        },
                    },

                });
                const { body } = await restOperation.response;
                await body.json();
                setIsSubmitting(false);
                navigate('/audio-transcribe/', {
                    state: { message: "Successfully triggered the generation of a summary" }
                });
            }

        } catch (error) {
            if (error instanceof ApiError) {
                if (error.response) {
                    const {
                        statusCode,
                        body
                    } = error.response;
                    console.error(`Received ${statusCode} error response with payload: ${body}`);
                }
                // Handle API errors not caused by HTTP response.
            }
            console.error(error)
            setIsSubmitting(false);
        }



    }, [isSubmitting, summaryType, file_name, navigate]);

    return (
        <Container
            header={
                <>
                    <Header variant="h2" description={file_original}>
                        Generate Summary
                    </Header>
                </>
            }
        >
            <Form
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button formAction="none" variant="link" onClick={() => navigate(-1)}>
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleSubmit}
                        >{isSubmitting ? <Spinner /> : "Submit"}</Button>
                    </SpaceBetween>
                }
            >
                <SpaceBetween direction="vertical" size="l">
                    <SummaryTypeFormField summaryType={summaryType} setSummaryType={setSummaryType} />
                </SpaceBetween>
            </Form>


        </Container >
    )



}

export default GenerateSummaryForm;