/* Copyright 2024 City Trax Limited. All rights reserved. */

import {
    Form,
    SpaceBetween,
    Button,
    FormField,
    Spinner,
    ProgressBar,
    Flashbar
} from '@cloudscape-design/components';
import { useNavigate } from "react-router-dom";
import { post, ApiError } from 'aws-amplify/api';
import { useState, useEffect, useRef, useCallback } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import SummaryTypeFormField from './SummaryTypeFormField';
import SelectTemplateFormField from './SelectTemplateFormField';
const mimeType = "audio/mpeg";



function RecordForm() {
    const navigate = useNavigate();
    const [time, setTime] = useState(0);
    const [isRecording, setIsRecording] = useState(false)
    const mediaRecorder = useRef(null);
    const [audioChunks, setAudioChunks] = useState([]);
    const [audio, setAudio] = useState(null);
    const [audioBlob, setAudioBlob] = useState(null);
    const [fileName, setFileName] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [audioLevel, setAudioLevel] = useState(0);
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const animationFrameRef = useRef(null);
    const [flashbarType, setFlashBarType] = useState(null)
    const [summaryType, setSummaryType] = useState({ label: "Standard", value: "3" });
    const [template, setTemplate] = useState({ label: "None", value: "0" })

    useEffect(() => {
        let interval;
        if (isRecording) {
            interval = setInterval(() => {
                setTime(prevTime => prevTime + 1); // Increment time by 1 second
            }, 1000); // Interval set to 1 second
        } else if (!isRecording && time !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isRecording, time]);

    const startRecording = async () => {
        if ("MediaRecorder" in window) {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                    video: false,
                });
                setIsRecording(true)
                setFlashBarType('in-progress')
                const media = new MediaRecorder(stream, { type: mimeType });
                mediaRecorder.current = media;
                mediaRecorder.current.start();
                let localAudioChunks = [];
                mediaRecorder.current.ondataavailable = (event) => {
                    if (typeof event.data === "undefined") return;
                    if (event.data.size === 0) return;
                    localAudioChunks.push(event.data);
                };
                setAudioChunks(localAudioChunks);

                const audioContext = new (window.AudioContext || window.webkitAudioContext)();
                audioContextRef.current = audioContext;
                const analyser = audioContext.createAnalyser();
                analyser.fftSize = 256; // Set FFT size (affects frequency resolution)
                analyserRef.current = analyser;
                const source = audioContext.createMediaStreamSource(stream);
                source.connect(analyser);
                visualizeAudio();
            } catch (err) {
                alert(err.message);
            }
        } else {
            alert("The MediaRecorder API is not supported in your browser.");
        }

    };

    const stopRecording = async () => {
        console.log('stoping recording')
        setIsRecording(false)
        setFlashBarType('success')

        await mediaRecorder.current.stop();
        mediaRecorder.current.stream.getTracks().forEach(track => track.stop());
        mediaRecorder.current.onstop = () => {
            const audioBlobWebM = new Blob(audioChunks, { type: 'audio/webm' });
            setAudioBlob(audioBlobWebM)
            const audioBlob = new Blob(audioChunks, { type: mimeType });
            const audioUrl = URL.createObjectURL(audioBlob);
            console.log(audioUrl)
            const parts = audioUrl.split('/');
            setFileName(parts[parts.length - 1] + '.webm')
            setAudio(audioUrl);
            setAudioChunks([]);
        };
        cancelAnimationFrame(animationFrameRef.current);
        if (audioContextRef.current) {
            audioContextRef.current.close();
        }
    };

    const formatTime = (totalSeconds) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        return [
            String(hours).padStart(2, '0'),
            String(minutes).padStart(2, '0'),
            String(seconds).padStart(2, '0')
        ].join(':');
    };

    const visualizeAudio = () => {
        const analyser = analyserRef.current;
        const dataArray = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(dataArray);
        const average = dataArray.reduce((a, b) => a + b) / dataArray.length;
        setAudioLevel(average);
        animationFrameRef.current = requestAnimationFrame(visualizeAudio);
    };

    const toggleRecording = async () => {
        if (isRecording) {
            await stopRecording()
            setTime(0)
        } else {
            startRecording()
        }
    }

    // TODO handle large file uploads in multipart
    // TODO handle file uploads from google drive, requires a google app account
    const handleSubmit = useCallback(async () => {
        if (isSubmitting) return;
        setIsSubmitting(true);
        try {
            const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
            if (authToken) {
                const restOperation = post({
                    apiName: 'transcribe',
                    path: '/transcription',
                    options: {
                        queryParams: {
                            file: fileName
                        },
                        headers: {
                            Authorization: authToken
                        },
                        body: {
                            summaryType: summaryType,
                            template: template
                        },
                    },

                });
                const { body } = await restOperation.response;
                const json = await body.json();
                const pre_signed_url = (json)?.pre_signed_url
                await new Promise((resolve, reject) => {
                    const xhr = new XMLHttpRequest();
                    xhr.upload.addEventListener('progress', e => {
                        console.log(Math.round((e.loaded / e.total) * 100))
                        setUploadProgress(Math.round((e.loaded / e.total) * 100))
                    });
                    xhr.addEventListener('load', () => resolve({ status: xhr.status, body: xhr.responseText }));
                    xhr.addEventListener('error', () => reject(new Error('File upload failed')));
                    xhr.addEventListener('abort', () => reject(new Error('File upload aborted')));
                    xhr.open('PUT', pre_signed_url, true);
                    xhr.send(audioBlob);
                });
                setIsSubmitting(false);
                navigate('/audio-transcribe/', {
                    state: { message: "Successfully uploaded audio file" }
                });
            }

        } catch (error) {
            if (error instanceof ApiError) {
                if (error.response) {
                    const {
                        statusCode,
                        body
                    } = error.response;
                    console.error(`Received ${statusCode} error response with payload: ${body}`);
                }
                // Handle API errors not caused by HTTP response.
            }
            console.error(error)
            setIsSubmitting(false);
        }


    }, [isSubmitting, audio, fileName, navigate, summaryType, template]);

    const items = (flashbarType === 'in-progress')
        ? [
            {
                content: (

                    <ProgressBar
                        value={Math.round(audioLevel)}
                        variant="flash"
                        description={formatTime(time)}
                        label="Recording in progress"
                    />

                ),
                type: 'in-progress',
                dismissible: false,
                loading: true,
                action: (<Button onClick={() => toggleRecording()}>
                    {'Stop Recording'}
                </Button>
                )
            },
        ]
        : [
            {
                type: 'success',
                content: (
                    <audio src={audio} controls></audio>
                ),
                header: 'Success',
                action: (
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button onClick={() => toggleRecording()}>Rerecord</Button>
                        <Button download={fileName} href={audio}>Download</Button>
                    </SpaceBetween>),
                dismissible: false,
            },
        ];

    return (
        <>
            <form onSubmit={e => e.preventDefault()}>
                <Form
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button formAction="none" variant="link" onClick={() => navigate(-1)}>
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                onClick={handleSubmit}
                                disabled={isSubmitting | audio === null}
                            >{isSubmitting ? <Spinner /> : "Submit"}</Button>
                        </SpaceBetween>
                    }
                >
                    <SpaceBetween direction="vertical" size="l">
                        <FormField
                            label="Record Audio"
                        >
                            <SpaceBetween direction="horizontal" size="xs">
                                {(flashbarType === null) ?
                                    <Button onClick={() => toggleRecording()}>
                                        Start Recording
                                    </Button>
                                    : null
                                }
                            </SpaceBetween>

                        </FormField>

                        {
                            (flashbarType === null) ?
                                null :
                                <Flashbar
                                    items={items}
                                />
                        }
                        <SummaryTypeFormField summaryType={summaryType} setSummaryType={setSummaryType} newTranscription={true} />
                        <SelectTemplateFormField template={template} setTemplate={setTemplate} newTranscription={true} />
                    </SpaceBetween>
                </Form>
            </form>
            {
                isSubmitting ? <ProgressBar
                    value={uploadProgress}
                    additionalInfo="Uploading Audio File"
                /> : null}

        </>


    )

}

export default RecordForm;

