/* Copyright 2024 City Trax Limited. All rights reserved. */

import { useLocation } from 'react-router-dom';
import {
    Container,
    Header,
    SpaceBetween,
    KeyValuePairs,
    StatusIndicator,
    Table,
    Button,
    ButtonDropdown,
    Spinner
} from '@cloudscape-design/components';
import { useState, useEffect, useCallback } from 'react';
import { get, del } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth'
import {
    useNavigate,
} from "react-router-dom";

function getDate(date) {
    var a = new Date(date * 1000);
    var year = a.getFullYear();
    var month = String(a.getMonth() + 1).padStart(2, '0');
    var day = String(a.getDate()).padStart(2, '0');
    var hour = String(a.getHours()).padStart(2, '0');
    var min = String(a.getMinutes()).padStart(2, '0');
    var time = `${day}/${month}/${year}, ${hour}:${min}`;
    return time;
}


function TranscriptionDetail() {
    const { state } = useLocation();
    const [file, setFile] = useState(null);
    const [selectedItem, setSelectedItem] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const [items, setItems] = useState([]);
    const navigate = useNavigate();


    const setTableItems = (file) => {
        const items_tmp = []
        items_tmp.push({ file_type: "Audio", status: <StatusIndicator>Available</StatusIndicator>, file_type_key: 'audio' })

        if (file.state === "complete") {
            items_tmp.push({ file_type: "Transcript", status: <StatusIndicator>Available</StatusIndicator>, file_type_key: 'transcription', status_key: 'complete' })
        } else if (file.state === "processing") {
            items_tmp.push({ file_type: "Transcript", status: <StatusIndicator type="pending">Pending</StatusIndicator>, file_type_key: 'transcription', status_key: 'pending' })
        }

        if (file['summary-state'] === "complete") {
            items_tmp.push({ file_type: "Summary", status: <StatusIndicator>Available</StatusIndicator>, file_type_key: 'summary', status_key: 'complete' })
        } else if (file['summary-state'] === "processing") {
            items_tmp.push({ file_type: "Summary", status: <StatusIndicator type="pending">Pending</StatusIndicator>, file_type_key: 'summary', status_key: 'pending' })
        }

        if (file['template-state'] === "complete") {
            items_tmp.push({ file_type: "Template", status: <StatusIndicator>Available</StatusIndicator>, file_type_key: 'template', status_key: 'complete' })
        } else if (file['template-state'] === "processing") {
            items_tmp.push({ file_type: "Template", status: <StatusIndicator type="pending">Pending</StatusIndicator>, file_type_key: 'template', status_key: 'pending' })
        }
        setItems(items_tmp)
    };

    const reload = useCallback(async () => {
        setIsLoading(true)
        setSelectedItem([])
        setItems([])
        const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
        if (authToken) {
            const restOperation = get({
                apiName: 'transcribe',
                path: '/transcription',
                options: {
                    headers: {
                        Authorization: authToken
                    },
                    queryParams: {
                        file: state.item.file_name,
                    }

                },
            });
            const { body } = await restOperation.response;
            const json = await body.json();
            setFile(json)
            await setTableItems(json)
            setIsLoading(false)
        }
    }, [state])

    useEffect(() => {
        reload();
    }, [reload]);



    const handleDownload = async () => {
        console.log(selectedItem[0])
        try {
            const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
            if (authToken) {
                const restOperation = get({
                    apiName: 'transcribe',
                    path: '/transcription/download',
                    options: {
                        queryParams: {
                            file: state.item.file_name,
                            type: selectedItem[0].file_type_key
                        },
                        headers: {
                            Authorization: authToken
                        }
                    },

                });
                const { body } = await restOperation.response;

                const json = await body.json();
                const pre_signed_url = json.pre_signed_url
                console.log(pre_signed_url)
                await fetch(pre_signed_url).then((response) => {
                    response.blob().then((blob) => {
                        const fileURL =
                            window.URL.createObjectURL(blob);
                        let alink = document.createElement("a");
                        alink.href = fileURL;
                        alink.download = state.item.file_name + "-" + selectedItem[0].file_type_key;
                        alink.click();
                    });
                });;
            }

        } catch (error) {
        }
    };

    const handleOptionSelection = async (e) => {
        const option = e.detail.id
        console.log(option)
        if (option === 'delete') {
            handleDelete()
        } else if (option === 'generate-summary') {
            console.log('generating summary...')
            console.log(selectedItem[0])
            navigate('/audio-transcribe/generate-summary', { state: { file_name: state.item.file_name, file_original: state.item.file_original } })
        } else if (option === 'populate-template') {
            console.log('populating template...')
            console.log(selectedItem[0])
            navigate('/audio-transcribe/populate-template', { state: { file_name: state.item.file_name, file_original: state.item.file_original } })
        }
    }

    const handleDelete = async () => {
        console.log('deleting')
        try {
            const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
            if (authToken) {
                const restOperation = del({
                    apiName: 'transcribe',
                    path: '/transcription',
                    options: {
                        queryParams: {
                            file: state.item.file_name,
                        },
                        headers: {
                            Authorization: authToken
                        }
                    },

                });
                const { body } = await restOperation.response;
                await body.json();
                navigate('/audio-transcribe/', {
                    state: { message: "Successfully deleted transcription" }
                });
            }

        } catch (error) {
        }
    }



    return (
        <>
            <SpaceBetween direction="vertical" size="m" >
                <Container
                    header={
                        <Header
                            variant="h2"
                            actions={<SpaceBetween direction="horizontal" size="xs">

                                <ButtonDropdown
                                    items={[
                                        {
                                            text: "Generate Summary",
                                            id: "generate-summary",
                                            disabled: ((file === null) || (file.state === 'processing') || (file['summary-type'] !== 'None')),
                                            disabledReason: (file === null) ? 'loading' : (file.state === 'processing') ? "Transcription still processing" : "This file already has a summary"

                                        },
                                        {
                                            text: "Populate Template",
                                            id: "populate-template",
                                            disabled: ((file === null) || (file.state === 'processing') || (file['template'] !== 'None')),
                                            disabledReason: (file === null) ? 'loading' : (file.state === 'processing') ? "Transcription still processing" : "There is already a template populated for this file."

                                        },
                                        { text: "Delete Transcription", id: "delete", disabled: false },

                                    ]}
                                    onItemClick={handleOptionSelection}
                                    disabled={false}
                                >
                                    Options
                                </ButtonDropdown>
                                <Button iconName="refresh" variant="normal" onClick={reload}>
                                    Refresh
                                </Button>
                            </SpaceBetween>}
                        >
                            Transcription Detail
                        </Header>

                    }
                >

                    <KeyValuePairs
                        columns={4}
                        items={[
                            { label: "Name", value: isLoading ? <Spinner /> : file.file_original },
                            { label: "Created", value: isLoading ? <Spinner /> : getDate(file.file_timestamp) },
                            { label: "Owner", value: isLoading ? <Spinner /> : file.file_owner },
                            { label: "Transcription Status", value: isLoading ? <Spinner /> : file.state === "complete" ? <StatusIndicator>Complete</StatusIndicator> : <StatusIndicator type="pending">Pending</StatusIndicator> }
                        ]}
                    />


                </Container >
                <Table
                    variant="container"
                    header={
                        <Header
                            actions={
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button
                                        iconName="download"
                                        variant="normal"
                                        disabled={((selectedItem.length === 0) || (selectedItem?.[0]?.status_key === 'pending'))}
                                        onClick={handleDownload}

                                    >
                                        Download
                                    </Button>

                                </SpaceBetween>}
                        >
                            Files
                        </Header>
                    }
                    selectionType="single"
                    selectedItems={selectedItem}
                    onSelectionChange={({ detail }) => {
                        console.log(selectedItem?.[0]?.status_key)
                        setSelectedItem(detail.selectedItems)
                    }}
                    trackBy="file_type"
                    loading={isLoading}
                    loadingText={"Loading Files"}
                    columnDefinitions={[
                        {
                            id: "file_type",
                            header: "File",
                            cell: item => item.file_type,
                            isRowHeader: true
                        },
                        {
                            id: "status",
                            header: "Status",
                            cell: item => item.status
                        }
                    ]}
                    items={items}
                />
            </SpaceBetween>






        </>
    )
}

export default TranscriptionDetail;