/* Copyright 2024 City Trax Limited. All rights reserved. */

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import TranscriptionForm from './AudioTranscribe/TranscriptionForm';
import TranscriptionList from './AudioTranscribe/TranscriptionList';
import GenerateSummaryForm from './AudioTranscribe/GenerateSummaryForm';
import PopulateTemplateForm from './AudioTranscribe/PopulateTemplateForm';
import TranscriptionDetail from './AudioTranscribe/TranscriptionDetail';
import ErrorPage from "./ErrorPage";
import Home from './Home';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Amplify } from 'aws-amplify';
import "@cloudscape-design/global-styles/index.css"
import AudioTranscribe from './AudioTranscribe/AudioTranscribe';


Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: window.config.userPoolClientId,
      userPoolId: window.config.userPoolId,
      identityPoolId: window.config.identityPoolId
    }
  },
  API: {
    REST: {
      'transcribe': {
        endpoint:
          window.config.audioTranscribeApiEndpoint,
      }
    }
  },
  Storage: {
    S3: {
      buckets: {
        'transcribe': {
          bucketName: window.config.audioTranscribeBucket,
          region: 'eu-west-2'
        }
      }
    }
  }
});
console.log(Amplify.getConfig())


let cityTraxRoutes = [];
if (window.config.audioTranscribeApiEndpoint !== undefined) {
  cityTraxRoutes.push({
    path: "/audio-transcribe/",
    element: <AudioTranscribe />,
    children: [
      {
        path: "/audio-transcribe/",
        element: <TranscriptionList />,
      },
      {
        path: "/audio-transcribe/new",
        element: <TranscriptionForm />,
      },
      {
        path: "/audio-transcribe/generate-summary",
        element: <GenerateSummaryForm />,
      },
      {
        path: "/audio-transcribe/populate-template",
        element: <PopulateTemplateForm />,
      },
      {
        path: "/audio-transcribe/:id",
        element: <TranscriptionDetail />,
      },

    ]
  })
}

cityTraxRoutes.push({
  index: true,
  element: <Home />,
})

const router = createBrowserRouter([
  {
    path: "/",
    element: <App children={undefined} />,
    errorElement: <ErrorPage />,
    children: cityTraxRoutes,
  },
]);



const root = ReactDOM.createRoot(
  document.getElementById('root')
);
root.render(
  <React.StrictMode >

    <RouterProvider router={router} />
  </React.StrictMode >
);
