/* Copyright 2024 City Trax Limited. All rights reserved. */

import {
    FormField,
    Select
} from '@cloudscape-design/components';
import { useCallback, useEffect, useState } from 'react';
import { get } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth'



function SummaryTypeFormField(props) {
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState('loading');

    const load = useCallback(async () => {
        try {
            const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
            if (authToken) {
                const restOperation = get({
                    apiName: 'transcribe',
                    path: '/summary',
                    options: {
                        headers: {
                            Authorization: authToken
                        }
                    },
                });
                const { body } = await restOperation.response;
                const json = await body.json();
                const options_array = (json)

                const result_array = []
                if (props.newTranscription) {
                    result_array.push({ label: "None", value: "0" })
                }
                if (options_array.length !== 0) {
                    result_array.push(...options_array.map((item, index) => ({
                        label: item.summary_type,
                        tags: [item.description],
                        value: (index + 1).toString()
                    })))
                    props.setSummaryType(result_array[1])
                } else {
                    props.setSummaryType(result_array[0])
                }

                setOptions(result_array)
            }
            setIsLoading('finished')

        } catch (error) {
            console.error(error)
        }

    }, []);

    useEffect(() => {
        load();
    }, [load]);


    return (

        < FormField
            label="Summary Type"
            description="Select which type of summary you would like"
        >
            <Select
                selectedOption={props.summaryType}
                onChange={({ detail }) => {
                    console.log(detail.selectedOption)
                    props.setSummaryType(detail.selectedOption)
                }
                }
                options={options}
                loadingText="Loading templates"
                statusType={isLoading}
            />
        </FormField >
    )
}

export default SummaryTypeFormField;

